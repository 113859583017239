.tox.tox-tinymce:hover {
    box-shadow: var(--shadow) !important;
    outline: none !important;
    border-color: var(--primary-color) !important;
}

.tox.tox-tinymce .tox-edit-area__iframe {
    background-color: transparent;
}

.tox.tox-tinymce .tox-statusbar {
    background-color: transparent;
}

.mce-content-body p {
    display: block;
}

.mce-content-body ul, .mce-content-body ol {
    line-height: 1.8rem;
    margin: 1.2rem 0
}

.mce-content-body ul, .mce-content-body ol, .mce-content-body li {
    list-style-type: unset;
    padding-left: 4rem;
}
.mce-content-body ol {
    list-style-type: numeric;
}
  .mce-content-body li {
    padding: unset;
}